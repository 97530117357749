import { ATTR_PREFIX } from "const";
import { NODE_TYPE } from "enums";
import { WordNode } from "types";
import { isMergeNode } from "./dom";

export * from "./dom";
export * from "./url";
export * from "./css";
export * from "./theme";
export function isEmpty(e: any) {
  return !e || !e.trim() || !isNaN(e) || "​" === e;
}

export function getMergeNode(node: HTMLElement) {
  if (node["isResolved"]) return false;
  // nodeType 1 --> element
  // nodeType 2 --> attr
  // nodeType 8 --> comment
  // nodeType 9 --> document

  let el: any = node;
  do {
    if (!el["isResolved"]) return el;
    el = el.parentElement || el.parentNode;
  } while (el !== null && el.nodeType === 1);

  return false;
}
function recursionChildren(node, callback) {
  if (node.childNodes)
    for (let i = 0, children = node.childNodes; i < children.length; i += 1) {
      const child = children[i];
      if (!child) return;
      callback(child);
      recursionChildren(child, callback);
    }
}

export function handleMergeNode(node: Node, treeWalker: TreeWalker, options) {
  let result: any = [];
  let el = node;
  for (; isMergeNode(node, options); ) {
    node = node.parentNode as Node;
    el?.textContent?.trim() !== node.textContent?.trim() && (el = node);
  }
  el.textContent?.trim() === node.textContent?.trim() && (node = el);

  for (; treeWalker.nextNode(); ) {
    if (!node.contains || !node.contains(treeWalker.currentNode)) {
      treeWalker.previousNode();
      break;
    }
  }

  const cloneNode = node.cloneNode(true) as HTMLElement;
  recursionChildren(node, (child: HTMLElement) => {
    if (child.nodeType === NODE_TYPE.ElementNode) {
      result.push({
        attributes: Array.from(child.attributes),
        child,
      });
    }
  });

  let i = 1;
  recursionChildren(cloneNode, (child: HTMLElement) => {
    if (child.nodeType === NODE_TYPE.ElementNode) {
      if (child.attributes) {
        for (; child.attributes.length > 0; ) {
          child.removeAttribute(child.attributes[0].name);
        }
      }
      child.setAttribute("et-" + i++, "");
    }
  });
  if (node) {
    node["isResloved"] = true;
    return [
      node,
      (cloneNode.innerHTML || cloneNode.textContent || "").replace(
        /<!--[^>]*-->/g,
        ""
      ),
      result,
    ];
  }
}

var lo = {};

export function getCurrentLang() {
  return "zh-cn";
}
export function tranferTextNodeToWordNode(nodes: any, customFilter?: any) {
  var n = getCurrentLang();
  return nodes
    .filter(function (node: any) {
      if (customFilter) {
        return customFilter(node);
      } else {
        return defaultFilter(node);
      }
    })
    .map(
      (function (lang) {
        return function (textNode: any) {
          let { node: element, words, type, properties, attrSetter } = textNode;
          if (!element.et) {
            element.et = { content: [] };
          }
          var et = element.et,
            translations = {},
            d = !!lo[lang] && lo[lang][words];
          if ((d && ((translations[lang] = words), (words = d)), properties)) {
            var htmlItem = et.content.find(function (item: any) {
              return item.html;
            });
            htmlItem
              ? Object.assign(htmlItem, {
                  original: words,
                  properties: properties,
                  translations: translations,
                })
              : et.content.push({
                  html: true,
                  original: words,
                  type: type,
                  properties: properties,
                  translations: translations,
                });
          }
          if (attrSetter) {
            var attrItem = et.content.find(function (item) {
              return item.attrSetter === attrSetter;
            });
            var p = {
              attrSetter: attrSetter,
              original: words,
              type: type,
              translations: translations,
            };
            attrItem ? Object.assign(attrItem, p) : et.content.push(p);
          }
          return element;
        };
      })(n)
    );
}

function getObjectValue(obj: any) {
  return Object.keys(obj).map(function (t) {
    return obj[t];
  });
}
export function convertCodeToChar(str) {
  str = "" + str;
  return ["&nbsp;", "&amp;", "&quot;", "&lt;", "&gt;"].some(function (t) {
    return -1 !== str.indexOf(t);
  })
    ? str
        .replace(/&nbsp;/g, " ")
        .replace(/&amp;/g, "&")
        .replace(/&quot;/g, '"')
        .replace(/&lt;/g, "<")
        .replace(/&gt;/g, ">")
    : str;
}

function defaultFilter(textNode: any) {
  const { node: element, words } = textNode;
  return (
    !element.weglot ||
    !element.weglot.content ||
    !element.weglot.content.some(function (e) {
      const { original, translations } = e;
      return (
        original === translations ||
        getObjectValue(translations).includes(convertCodeToChar(words))
      );
    })
  );
}

export function getAttrEtNum(node: HTMLElement) {
  if (
    node &&
    node.nodeType === NODE_TYPE.ElementNode &&
    node.attributes &&
    node.attributes[0]
  ) {
    const num = parseInt(node.attributes[0].name.split(ATTR_PREFIX)[1]);
    return isNaN(num) ? false : num;
  }
}

export function makeElementUsingProperties(
  word: WordNode,
  targetStr: string,
  properties: any,
  words?: WordNode[]
) {
  if (word.nodeType === NODE_TYPE.ElementNode) {
    const makeElement = function (
      target: string,
      word: WordNode,
      properties: any
    ) {
      var div = document.createElement("div");
      div.innerHTML = target;

      return (function e(word: WordNode, div: HTMLElement, properties) {
        const documentFragment = document.createDocumentFragment();
        if (word.nodeType !== 1) {
          documentFragment.appendChild(div);
          return documentFragment;
        }

        for (let i = 0; i < div.childNodes.length; i++) {
          var firstChild = div.firstChild as HTMLElement;
          var etNum = getAttrEtNum(firstChild);
          if (etNum) {
            var u = properties[etNum - 1];
            if (!u) continue;
            var l = u.used ? u.child.cloneNode(true) : u.child,
              d = e(l, firstChild, properties);
            if (d.contains(l)) {
              console.error(
                "There is an HTML error in the translation of: " +
                  word.innerHTML
              );
              return documentFragment;
            }
            l.innerHTML = "";
            l.appendChild(d);

            documentFragment.appendChild(l);
            document.createDocumentFragment().appendChild(firstChild);
            u.used = true;
          } else {
            documentFragment.appendChild(firstChild);
          }
        }
        return documentFragment;
      })(word, div, properties);
    };

    word.innerHTML = "";
    word.appendChild(makeElement(targetStr, word, properties));
    return;
  }
  if (targetStr.indexOf("<") !== -1 && targetStr.indexOf(">") !== -1) {
    if (!word.parentNode) {
      console.warn(word, "Unable to translate some words, please contact");
      return;
    }
    if (word.parentNode.childNodes.length === 1) {
      word.parentNode.et = word.et;
      return void (words
        ? words.push(word.parentNode)
        : makeElementUsingProperties(
            word.parentNode.parentNode as WordNode,
            targetStr,
            properties
          ));
      // console.log(words, word.parentNode, targetStr, properties);
    }

    const span = document.createElement("span") as WordNode;
    span.et = word.et;
    word.parentNode.replaceChild(span, word);
    words
      ? words.push(span)
      : makeElementUsingProperties(word.parentNode, targetStr, properties);
  } else {
    word.textContent = targetStr;
  }
}
