import { Theme } from "types/theme";
const DOC_ROOT = process.env.DOC_ROOT;
const loadStyle = function (theme: Theme) {
  var link = document.createElement("link");
  link.rel = "stylesheet";
  link.type = "text/css";
  link.href = DOC_ROOT + `/themes/${theme}.css`;
  var head = document.getElementsByTagName("head")[0];
  head.appendChild(link);
};

export { loadStyle };
