import {
  findClosest,
  getFilter,
  getMergeNode,
  handleMergeNode,
  isEmpty,
  makeElementUsingProperties,
  matches,
  querySelectorAll,
} from "utils";

import { TEXT_NODE_TYPE } from "enums";
import { IDefiniton, TextNode, TranslateOptions, WordNode } from "types";
import pkg from "../package.json";
import { getDefinitions } from "./definiton";

export default class Translate {
  public version?: string;
  private options: TranslateOptions | null;
  private weakmap: WeakMap<Node, any>;
  private definitions: IDefiniton[];
  protected allWords: WordNode[];

  constructor(options: TranslateOptions) {
    this.options = options;

    if (this.options.excluded_blocks) {
      this.options.excluded_blocks.push(".et-select");
    } else {
      this.options.excluded_blocks = [".et-select"];
    }
    if (!this.options.noTranslateAttribute) {
      this.options.noTranslateAttribute = "data-notranslate";
    }
    this.weakmap = new WeakMap();
    this.allWords = [];
    this.definitions = getDefinitions(options);

    this.initVersion();
  }

  private initVersion() {
    this.version = pkg.version;
    if (this.options?.logVersion) {
      console.log(
        "----------- Tranlate Version " + this.version + "-----------"
      );
    }
  }

  protected handleExcludedBlocks(node, config) {
    const { excluded_blocks, noTranslateAttribute } = config;
    if (excluded_blocks && excluded_blocks.length) {
      const excluded_blocks_str = excluded_blocks.map((e) => e).join(",");
      // 匹配上则不翻译，标记不翻译
      if (matches(node, excluded_blocks_str)) {
        return void node.setAttribute(noTranslateAttribute, "manual");
      }
      var children = querySelectorAll(node, excluded_blocks_str);
      // 所有子如果满足的话， 也标记未不翻译
      if (children)
        for (var i = 0, s = children; i < s.length; i += 1) {
          const item = s[i] as HTMLElement;
          item.setAttribute(noTranslateAttribute, "manual");
        }
    }
  }

  protected getTextNodes(node?: HTMLElement) {
    if (!node) {
      node = document as unknown as HTMLElement;
    }

    this.handleExcludedBlocks(node, this.options);

    return [
      ...this.getTitleNodes(node),
      ...this.getBodyTextNodes(node),
      ...this.getAttrTextNodes(node),
    ];
  }
  protected setTextNodes(words: WordNode[], to: string) {
    for (let n = 0; n < words.length; n++) {
      const word = words[n];
      const content = word.et?.content;
      if (word.et && content && word.isConnected) {
        for (var i = 0; i < content.length; i++) {
          const { original, properties, attrSetter, translations } = content[i];

          const targetStr = translations[to] || original;

          if (properties) {
            word.et.setted = true;
            makeElementUsingProperties(word, targetStr, properties, words);
          }

          if (attrSetter) {
            word.et.setted = true;
            attrSetter(word, targetStr, original);
          }
        }
        word.isResloved = false;
      }
    }
  }

  protected getTitleNodes(node: HTMLElement): TextNode[] {
    const title = document.getElementsByTagName("title")[0];

    if (
      node !== document.documentElement ||
      !document.title ||
      !title ||
      findClosest(title)
    ) {
      return [];
    }
    return [
      {
        node: title.firstChild as HTMLElement,
        type: TEXT_NODE_TYPE.Title,
        words: title.textContent,
      },
    ];
  }

  public getNewWordsNotRepeat(words: WordNode[]) {
    const temp: WordNode[] = [];
    for (var i = 0; i < words.length; i++) {
      const word = words[i];
      if (this.allWords.indexOf(word)) {
        temp.push(word);
      }
    }
    return temp;
  }

  protected getAttrTextNodes(node: HTMLElement) {
    const result: TextNode[] = [];
    this.definitions.forEach((def) => {
      const { attribute, selectors } = def;
      const selectors_str = selectors.join(",");
      const nodeList =
        node.childElementCount > 0
          ? node.querySelectorAll(selectors_str)
          : node.matches && node.matches[selectors_str]
            ? [node]
            : [];
      for (let i = 0; i < nodeList.length; i++) {
        const n = nodeList[i];
        if (!findClosest(n)) {
          const attr = attribute.get(n as HTMLElement);
          if (!isEmpty(attr)) {
            result.push({
              node: n,
              words: attr,
              type: TEXT_NODE_TYPE.Attr,
              attrSetter: attribute.set,
              attrName: attribute.name,
            });
          }
        }
      }
    });

    return result;
  }

  public getTextFromWordNodes(words?: WordNode[]): { t: string; w: string }[] {
    if (!words) {
      words = this.allWords;
    }
    const result: any = [];
    const visited = {};
    for (let i = 0; i < words.length; i++) {
      const word = words[i];
      if (word.et) {
        const { content } = word.et;
        for (let j = 0; j < content.length; j++) {
          const { original, type } = content[j];
          if (!visited[original]) {
            visited[original] = true;
            result.push({ t: type, w: original });
          }
        }
      }
    }
    return result;
  }

  protected getBodyTextNodes(node: HTMLElement): TextNode[] {
    const result: TextNode[] = [];
    const treeWalker = document.createTreeWalker(
      node,
      NodeFilter.SHOW_TEXT,
      getFilter
    );
    let current: Node | null;
    for (; (current = treeWalker.nextNode());) {
      let textNode: any;
      if (this.options?.enableMerge) {
        textNode = this.getMergeTextNode(current, treeWalker);
      } else {
        textNode = this.getSingleTextNode(current);
      }
      if (textNode) {
        result.push(textNode);
      }
    }
    return result;
  }

  private getSingleTextNode(node: Node): TextNode | undefined {
    const text = node.textContent;
    if (!isEmpty(text)) {
      return {
        node,
        words: text,
        type: TEXT_NODE_TYPE.Normal,
        properties: {},
      };
    }
  }

  private getMergeTextNode(
    node: Node,
    treeWalker: TreeWalker
  ): TextNode | undefined {
    const mergeNode = getMergeNode(node as HTMLElement);
    if (mergeNode && this.weakmap.has(mergeNode)) {
      const [node, words, properties] = this.weakmap.get(mergeNode);
      return {
        node,
        words,
        type: TEXT_NODE_TYPE.Normal,
        properties,
      };
    }

    const result = handleMergeNode(node, treeWalker, this.options);
    if (result) {
      const [node, words, properties] = result;
      if (!isEmpty(words)) {
        this.weakmap.set(node, result);
        return {
          node,
          words,
          type: TEXT_NODE_TYPE.Normal,
          properties,
        };
      }
    }
  }
}
