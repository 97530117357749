import EasyTranslate from "../easy-translate";
import { getIsMobile } from "../utils/device";

const translateEl = document.createElement("div");

const mobileWidth = 767; // 移动端屏幕宽度临界值
const isMobile = getIsMobile(mobileWidth)

function injectElToBody() {
  const translateElName = "brooklyn_container";
  translateEl.className = translateElName;
  document.body.appendChild(translateEl);
}

function injectElToAside() {
  const translateElName = "brooklyn_mobile_container";
  translateEl.className = translateElName;
  const asideElName = ".side-menu nav";
  const asideEl = document.querySelector(asideElName);
  if (asideEl) {
    asideEl.appendChild(translateEl);
  }
}

const translateBrooklyn = function () {
  if (!isMobile) {
    injectElToBody();
    window.et_translate = new EasyTranslate({
      excluded_blocks: [
        ".et-select",
        ".et-select-mobile",
        ".ws-currency-trigger", // 价格
        "ws-price",
        ".checkoutrightbody .shoppingcarlist .price",
        ".checkoutrightbody .computedlist",
        ".checkoutrightbody .computetotal",
        ".checkoutrightbody .computetotalTip span"
      ],
      mount: translateEl,
      isMobile
    });
  } else {
    injectElToAside();
    window.et_translate = new EasyTranslate({
      excluded_blocks: [
        ".et-select",
        ".et-select-mobile",
        ".ws-currency-trigger", // 价格
        "ws-price",
        ".checkoutrightbody .shoppingcarlist .price",
        ".checkoutrightbody .computedlist span:nth-child(2)",
        ".checkoutrightbody .computetotal>p"
      ],
      mount: translateEl,
      isMobile
    });
  }
};

export { translateBrooklyn };
