import { initAttr, removeWWW, setSrcsetValue } from "utils";
import { TranslateOptions, IDefiniton } from "types";

export function getDefinitions(options?: TranslateOptions): IDefiniton[] {
  var definitons: IDefiniton[] = [
    { type: 1, selectors: ["[title]"], attribute: initAttr("title") },
    {
      type: 2,
      selectors: ["input[type='submit']", "input[type='button']", "button"],
      attribute: initAttr("value"),
    },
    {
      type: 3,
      selectors: ["input[placeholder]", "textarea[placeholder]"],
      attribute: initAttr("placeholder"),
    },
    {
      type: 4,
      selectors: [
        "meta[name='description']",
        "meta[property='og:description']",
        "meta[property='og:site_name']",
        "meta[property='og:image:alt']",
        "meta[name='twitter:description']",
        "meta[itemprop='description']",
        "meta[itemprop='name']",
      ],
      attribute: initAttr("content"),
    },
    { type: 7, selectors: ["img"], attribute: initAttr("alt") },
    {
      type: 8,
      selectors: ["[href$='.pdf']", "[href$='.docx']", "[href$='.doc']"],
      attribute: initAttr("href"),
    },
    {
      type: 9,
      selectors: ["meta[property='og:title']", "meta[name='twitter:title']"],
      attribute: initAttr("content"),
    },
  ];
  if (!options) return definitons;
  if (options.enableMedia) {
    definitons.push(
      {
        type: 5,
        selectors: [
          "youtube.com",
          "youtu.be",
          "vimeo.com",
          "dailymotion.com",
        ].map(function (e) {
          return "iframe[src*='" + e + "']";
        }),
        attribute: initAttr("src"),
      },
      {
        type: 6,
        selectors: ["img", "source"],
        attribute: {
          name: "src",
          get: function (node) {
            const srcUrl = node.getAttribute("src");
            if (!srcUrl || !srcUrl.split) return "";
            if (srcUrl.indexOf("data:image") === 0) return "";
            const [domain, queryString] = srcUrl.split("?");
            if (queryString) {
              node["queryString"] = queryString;
            }
            console.log(domain);
            return domain;
          },
          set: function (node, srcUrl, srcsetUrl) {
            const src = node.getAttribute("src");
            const srcset = node.getAttribute("srcset");
            if (srcUrl === srcsetUrl) {
              if (
                (node.removeAttribute("data-wgtranslated"), node["isChanged"])
              ) {
                const url =
                  "" +
                  srcUrl +
                  (node["queryString"] ? "?" + node["queryString"] : "");

                node.setAttribute("src", url);
                setSrcsetValue(node, url);

                if (node.hasAttribute("wgsrcset")) {
                  node.setAttribute(
                    "srcset",
                    node.getAttribute("wgsrcset") || node.dataset.srcset || ""
                  );

                  node.removeAttribute("wgsrcset");
                }
              }
            } else {
              const srcList = src?.split("?");
              if (srcList) {
                const [domain] = srcList;
                if (domain !== srcUrl && srcsetUrl !== srcsetUrl) {
                  node.setAttribute("src", srcUrl);
                }
                setSrcsetValue(node, srcUrl);

                if (node.hasAttribute("srcset") && srcset) {
                  node.setAttribute("wgsrcrset", srcset);
                  node.setAttribute("srcest", "");
                }

                node.dataset.wgtranslated = "1";
                node["isChanged"] = true;
              }
            }
          },
        },
      },
      {
        type: 6,
        selectors: ["meta[property='og:image']", "meta[property='og:logo']"],
        attribute: initAttr("content"),
      },
      { type: 6, selectors: ["img"], attribute: initAttr("srcset") }
    );
  }
  if (options.enableExternal) {
    var n = removeWWW(
      (function () {
        var e = window.location,
          t = e.hostname,
          n = e.search;
        if ("ve-weglot.com" !== t || !n) return t;
        var r = decodeURIComponent(n).match(/url=https?:\/\/([^/]+)/);
        if (r) {
          return r[1];
        } else {
          console.warn("[Weglot] Unable to get current hostname");
          return t;
        }
      })()
    );
    definitons.push(
      { type: 10, selectors: ["iframe"], attribute: initAttr("src") },
      { type: 10, selectors: ["a[rel=external]"], attribute: initAttr("href") },
      {
        type: 10,
        selectors: ["http:", "https:", "//"].map(function (e) {
          return '[href^="' + e + '"]:not(link)';
        }),
        attribute: {
          name: "href",
          get: function (e) {
            const a = e as HTMLLinkElement;
            if (!a.href || !a.href.split) return "";
            var t = a.href.split("/")[2];
            return t && removeWWW(t) !== n ? a.getAttribute("href") : "";
          },
          set: function (e, t) {
            return e.setAttribute("href", t);
          },
        },
      }
    );
  }
  if (options.extra_definitions && options.extra_definitions.length) {
    const list = options.extra_definitions;
    for (var i = 0; i < list.length; i++) {
      const { type, selector, attribute } = list[i];
      if (attribute && selector) {
        definitons.push({
          type,
          selectors: [selector],
          attribute: {
            name: attribute,
            get: function (node: HTMLElement) {
              return node.getAttribute(attribute);
            },
            set: function (node: HTMLElement, value: string) {
              return node.setAttribute(attribute, value);
            },
          },
        });
      } else {
        console.warn(
          "Each extra definition option needs at least {attribute,selector}"
        );
      }
    }
  }
  return definitons;
}
