const API_ROOT = process.env.API_ROOT;

const GET_CONFIG = API_ROOT + "/api/language_list";

const TRANSLATE = API_ROOT + "/api/translate_language";

export const getLangList = async (shop: string) => {
  const res = await fetch(GET_CONFIG + `?shop=${shop}`);
  return res.json();
};

export const getTranslate = async (params: {
  request_url: string;
  l_from: string;
  l_to: string;
  title: string;
  words: any[];
}) => {
  const request = new Request(TRANSLATE + `?shop=${window.location.hostname}`, {
    method: "post",
    headers: {
      "content-type": "application/json",
    },
    body: JSON.stringify(params),
  });
  const res = await fetch(request);
  return res.json();
};
