import EasyTranslate from "src/easy-translate";
import { getIsMobile } from "utils/device";

const translateVogue = function () {
  // 先进行翻译初始化
  const mobileWidth = 767; // 移动端屏幕宽度临界值，< 768px 为移动端
  let isMobile = getIsMobile(mobileWidth); // 是否是移动端

  const div = document.createElement("div");
  div.className = 'et';

  window.et_translate = new EasyTranslate({
    logVersion: false,
    enableMerge: false,
    excluded_blocks: [
      ".et-select",
      ".et-select-mobile",
      ".selectpricewrap",
      ".coupon-popover-content .count-down-content",
      "[class^=upSelling-custom-options-global-timeout]",
      //不翻译结账按钮
      ".inlineCart .check-button-group", // 结账按钮
      "[v-cloak]",
      ".checkoutrightbody .shoppingcarlist .price",
      ".checkoutrightbody .computedlist",
      ".checkoutrightbody .computetotal",
      ".checkoutrightbody .computetotalTip span",
      ".langs_div_class"
    ],
    extra_definitions: [
      {
        type: 13,
        selector: "[data-title]",
        attribute: "data-title",
      },
      {
        type: 14,
        selector: "[data-description]",
        attribute: "data-description",
      },
    ],
    mount: div,
    isMobile
  });

  const isOrderPage = new RegExp(/\/order?-\w+|orders\/\d+/).test(
    location.pathname
  );

  if (isOrderPage) {
    return;
  }

  // pc
  if (!isMobile) {
    // 根据页面或者节点，判断是否挂载语言选择器
    const rightSearchBox = document.querySelector(".header-right.search-box");

    if (!rightSearchBox) {
      return;
    }

    const searchBox = rightSearchBox as HTMLElement;
    // get width
    let width = searchBox.clientWidth;

    const windowWidth = window.innerWidth;

    /**
     * pad 时，宽度 + 42px；
     * pc + 150；
     * 移动端时，header 是另外一种样式，不考虑
     * */
    if (windowWidth < 991) {
      width += 42;
    } else {
      width += 150;
    }

    // change style
    searchBox.setAttribute(
      "style",
      searchBox.getAttribute("style") + `width: ${width}px;`
    );

    const search = rightSearchBox.querySelector(".search");
    const account = rightSearchBox.querySelector(".account");
    const cart = rightSearchBox.querySelector(".cart");

    const mountPoint = search || account || cart;

    if (mountPoint) {
      rightSearchBox.insertBefore(div, mountPoint);
    } else {
      rightSearchBox.appendChild(div);
    }
  } else {
    // mobile
    const mountPoint = document.querySelector(".section_drawer .lang_currency, .drawer-menu__content .lang_currency");

    if (!mountPoint) {
      return;
    }

    const li = document.createElement("li");
    li.append(div);

    mountPoint.after(li);
  }
};

export { translateVogue };
