import EasyTranslate from "src/easy-translate";
import { getIsMobile } from "utils/device";
const translateShowtime = function () {
  const inOrderPge = new RegExp(/\/order?-\w+|orders\/\d+/).test(location.pathname);
  const mobileWidth = 768; // 移动端屏幕宽度临界值

  const isMobile = getIsMobile(mobileWidth)

  if (!isMobile) {
    const searchbarEl =
      document.querySelector("#header .search-par") || undefined;
    let div = document.createElement("div");
    if (searchbarEl) {
      searchbarEl?.prepend(div);
    }
    window.et_translate = new EasyTranslate({
      logVersion: false,
      enableMerge: false,
      excluded_blocks: [
        ".et-select",
        ".et-select-mobile",
        ".selectpricewrap",
        ".coupon-popover-content .count-down-content",
        "[class^=upSelling-custom-options-global-timeout]",
        "[v-cloak]",
        ".checkoutrightbody .shoppingcarlist .price",
        ".checkoutrightbody .computedlist",
        ".checkoutrightbody .computetotal",
        ".checkoutrightbody .computetotalTip span",
        ".langs_div_class"
      ],
      mount: div,
      isMobile
    });
  } else {
    const menuEl =
      document.querySelector("#header .mobile-nav-bar .mobile-nav-left") ||
      undefined;

    let div = document.createElement("div");
    if (menuEl) {
      menuEl?.append(div);
      div.setAttribute("style", "padding:0 25px;display:flex;");
    }
    window.et_translate = new EasyTranslate({
      logVersion: false,
      enableMerge: false,
      excluded_blocks: [
        ".et-select",
        ".et-select-mobile",
        ".selectpricewrap",
        ".coupon-popover-content .count-down-content",
        "[class^=upSelling-custom-options-global-timeout]",
        "[v-cloak]",
        ".langs_div_class"
      ],
      mount: div,
      isMobile
    });
  }
  if (inOrderPge) {
    let div = document.createElement("div");

    window.et_translate = new EasyTranslate({
      logVersion: false,
      enableMerge: false,
      excluded_blocks: [
        ".et-select",
        ".et-select-mobile",
        ".selectpricewrap",
        ".coupon-popover-content .count-down-content",
        "[class^=upSelling-custom-options-global-timeout]",
        "[v-cloak]",
        ".langs_div_class"
      ],
      mount: div,
      isMobile
    });
  }
};

export { translateShowtime };

