import EasyTranslate from "src/easy-translate";
import { getIsMobile } from "utils/device";


const translateDefault = function () {
  const inOrderPge = new RegExp(/\/order?-\w+|orders\/\d+/).test(location.pathname);
  const mobileWidth = 767; // 移动端屏幕宽度临界值
  const isMobile = getIsMobile(mobileWidth)

  if (!isMobile) {
    const searchContainer = document.querySelector(".headtitle .titleright");
    let div = document.createElement("div");
    div.setAttribute(
      "style",
      "display:flex;align-items:center;white-space: nowrap;padding: 0 15px;"
    );
    div.className = "et";
    if (searchContainer) {
      searchContainer.prepend(div);
    }
    window.et_translate = new EasyTranslate({
      logVersion: false,
      enableMerge: false,
      excluded_blocks: [
        ".et-select",
        ".et-select-mobile",
        ".selectpricewrap",
        ".coupon-popover-content .count-down-content",
        "[class^=upSelling-custom-options-global-timeout]",
        "[v-cloak]",
        ".checkoutrightbody .shoppingcarlist .price",
        ".checkoutrightbody .computedlist",
        ".checkoutrightbody .computetotal",
        ".checkoutrightbody .computetotalTip span",
        ".langs_div_class"
      ],
      extra_definitions: [
        {
          type: 13,
          selector: "[data-title]",
          attribute: "data-title",
        },
        {
          type: 14,
          selector: "[data-description]",
          attribute: "data-description",
        },
      ],
      mount: div,
      isMobile
    });
  } else {
    // drawer-menu secondary_title
    // const prevElement = document.querySelector(".drawer-menu ul[class='secondary_title'] li[class='titlemenulist-login']")
    let containerEl = document.querySelector(
      ".drawer-menu__body ul[class='secondary_title']"
    );
    const li = document.createElement("li");
    window.et_translate = new EasyTranslate({
      logVersion: false,
      enableMerge: false,
      excluded_blocks: [
        ".et-select",
        ".et-select-mobile",
        ".selectpricewrap",
        ".coupon-popover-content .count-down-content",
        "[class^=upSelling-custom-options-global-timeout]",
        "[v-cloak]",
        ".langs_div_class"
      ],
      mount: li,
      isMobile
    });
    if (containerEl) {
      containerEl.append(li);
    }
  }

  if (inOrderPge) {
    let div = document.createElement("div");
    window.et_translate = new EasyTranslate({
      logVersion: false,
      enableMerge: false,
      excluded_blocks: [
        ".et-select",
        ".et-select-mobile",
        ".selectpricewrap",
        ".coupon-popover-content .count-down-content",
        "[class^=upSelling-custom-options-global-timeout]",
        "[v-cloak]",
        ".langs_div_class"
      ],
      extra_definitions: [
        {
          type: 13,
          selector: "[data-title]",
          attribute: "data-title",
        },
        {
          type: 14,
          selector: "[data-description]",
          attribute: "data-description",
        },
      ],
      mount: div,
      isMobile
    });
  }
};
export { translateDefault };

