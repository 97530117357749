export enum TEXT_NODE_TYPE {
  Normal = 1,
  Merge = 2,
  Attr = 3,
  Title = 9,
}


/**
 * https://docs.microsoft.com/en-us/uwp/api/windows.data.xml.dom.nodetype?view=winrt-22621
 */
export enum NODE_TYPE {
  AttributeNode = 2,
  // The node is an XmlAttribute type.

  CommentNode = 8,
  // The node is an XmlComment type.

  DataSectionNode = 4,
  // The node is an XmlCDataSection type.

  DocumentFragmentNode = 11,
  // The node is an XmlDocumentFragment type.

  DocumentNode = 9,
  // The node is an XmlDocument type.

  DocumentTypeNode = 10,
  // The node is an XmlDocumentType type.

  ElementNode = 1,
  // The node is an XmlElement type.

  EntityNode = 6,
  // The node is an DtdEntity type.

  EntityReferenceNode = 5,
  // The node is an XmlEntityReference object.

  Invalid = 0,
  // Default value. Never used by the API.

  NotationNode = 12,
  // The node is a DtdNotation type.

  ProcessingInstructionNode = 7,
  // The node is an XmlProcessingInstruction type.

  TextNode = 3,
  // The node is an XmlText type.
}
