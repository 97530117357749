import { TranslateOptions } from "types";

export function createDomMethod<T>(
  methodName: string,
  defaultValue: T
): (node: HTMLElement, params: string) => T {
  return function (node: HTMLElement, params: string) {
    try {
      return node[methodName] ? node[methodName](params) : defaultValue;
    } catch (e) {
      console.warn(e, {
        consoleOverride: "Your CSS rules are incorrect: " + params,
      });
    }
    return defaultValue;
  };
}

export const querySelectorAll = createDomMethod<[]>("querySelectorAll", []);
export const matches = createDomMethod<boolean>("matches", false);

export function findClosest(
  node: HTMLElement | Element,
  noTranslateAttribute = "data-notranslate"
) {
  if (!node.closest) {
    node = node.parentElement ? node.parentElement : node;
  }
  return node.closest && node.closest("[" + noTranslateAttribute + "]");
}

export function initAttr(attrName: string) {
  return {
    name: attrName,
    get: function (node: HTMLElement) {
      return node.getAttribute(attrName);
    },
    set: function (node: HTMLElement, value: any) {
      return node.setAttribute(attrName, value);
    },
  };
}

export function setSrcsetValue(node: HTMLElement, value: string) {
  if (node.parentElement && node.parentElement.tagName === "PICTURE") {
    const children = node.parentElement.children;
    for (let i = 0; i < children.length; i++) {
      const child = children[i];
      "SOURCE" === child.tagName &&
        child.getAttribute("srcset") &&
        child.setAttribute("srcset", value);
    }
  }
}

export function getFilter(node: Node) {
  return !isTextNode(node) || findClosest(node as HTMLElement)
    ? NodeFilter.FILTER_REJECT
    : NodeFilter.FILTER_ACCEPT;
}

function isOnlyInlineChildNode(node: Node, options: TranslateOptions) {
  return createCheckMethod("__onlyInlineChildsNodes", node, function (node) {
    if (!node.childNodes) return true;

    const childNodes = node.childNodes;

    for (let i = 0; i < childNodes.length; i += 1) {
      const child = childNodes[i];
      if (
        // child["weglot"] ||
        !Kt(child, options) ||
        !isOnlyInlineChildNode(child, options)
      )
        return false;
    }
    return true;
  });
}
function Kt(node: Node, options: TranslateOptions) {
  const el = node as HTMLElement;
  return (
    !(!options.mergeNodes || -1 === options.mergeNodes.indexOf(el.nodeName)) ||
    (el.dataset && el.dataset.merge) ||
    (options.selectorMerging &&
      el.matches &&
      el.matches(options.selectorMerging))
  );
}

function containsNoTranslateNodes(node: Node, options: TranslateOptions) {
  return createCheckMethod("__containsNoTranslateNodes", node, function (e) {
    return (
      e.nodeType === 1 &&
      !!e.querySelector("[" + options.noTranslateAttribute + "]")
    );
  });
}

export function isMergeNode(node: Node, options: TranslateOptions) {
  return createCheckMethod("__validMergeNodes", node, function (node) {
    return (
      node &&
      isOnlyInlineChildNode(node, options) &&
      isTextNode(node) &&
      !containsNoTranslateNodes(node, options)
    );
  });
}

function isTextNode(node: Node) {
  return createCheckMethod("__validTextNodes", node, function (node) {
    return !(
      !node.textContent ||
      !node.textContent.trim() ||
      -1 !== node.textContent.indexOf("BESbswy") ||
      (node.parentNode &&
        node.parentNode.nodeName &&
        -1 !==
          ["script", "style", "noscript"].indexOf(
            node.parentNode.nodeName.toLowerCase()
          )) ||
      (function (e) {
        if (!(e = e.trim())) return false;
        var t = e.charAt(0);
        if ("[" !== t && "{" !== t) return false;
        var n = e[e.length - 1];
        if ("]" !== n && "}" !== n) return false;
        return (
          (e = e
            .replace(/\\(?:["\\\/bfnrt]|u[0-9a-fA-F]{4})/g, "@")
            .replace(
              /"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g,
              "]"
            )
            .replace(/(?:^|:|,)(?:\s*\[)+/g, "")),
          /^[\],:{}\s]*$/.test(e)
        );
      })(node.textContent)
    );
  });
}

function createCheckMethod(
  methodName: string,
  node: Node,
  callback: (node: HTMLElement) => void
) {
  const methodObject = node && node[methodName];
  if (methodObject && methodObject.textContent === node.textContent) {
    return methodObject.result;
  }
  const result = callback(node as HTMLElement);
  if (node) {
    node[methodName] = { result, textContent: node.textContent };
  }
  return result;
}
